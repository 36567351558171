<template>
    <div
        v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'admin-operation', 'admin-bd', 'superadmin-tech', 'superadmin-product', 'user-management'])"
        class="uk-container uk-container-expand uk-margin-top card-scrollable"
    >
        <div v-if="isLoading" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>
        <template v-else-if="!isLoading && detail_applicant !== null">
            <div class="uk-flex uk-flex-between uk-flex-middle">
                <p class="uk-text-large uk-text-bold">{{ detail_applicant.fullname || '-' }}</p>
                <router-link :to="`/admin/marketing/silver_package/detail/${$route.params.id}`" uk-close></router-link>
            </div>
            <div class="uk-flex uk-flex-middle">
                <div>
                    <p>Position:</p>
                    <p class="uk-text-bold">{{ detail_applicant.job_title || '-' }}</p>
                </div>
                <div class="uk-margin-large-left">
                    <p>Status:</p>
                    <p class="uk-text-bold">Sent to HRD</p>
                </div>
                <div class="uk-margin-large-left">
                    <p>Application Date</p>
                    <p class="uk-text-bold">{{ dateFormat(detail_applicant.created_at) || '-' }}</p>
                </div>
            </div>
            <hr>
            <div>
                <ul uk-tab>
                    <li><a href="#">CANDIDATE’S CV</a></li>
                    <li><a href="#">OTHER JOB APPLICATIONS</a></li>
                </ul>
                <ul class="uk-switcher uk-margin">
                    <li>
                        <div class="uk-flex uk-flex-stretch">
                            <div class="uk-flex uk-flex-middle uk-margin-top uk-width-1-3">
                                <img :src="`${images}/call-black.svg`" alt="Call black icon">
                                <span class="uk-margin-left">{{ detail_applicant.phone_number }}</span>
                            </div>
                            <div class="uk-flex uk-flex-middle uk-margin-top uk-width-1-3">
                                <img :src="`${images}/email.svg`" alt="Email icon">
                                <span class="uk-margin-left">{{ detail_applicant.email }}</span>
                            </div>
                        </div>
                        <div class="uk-flex">
                            <div class="uk-flex uk-flex-middle uk-margin-top uk-width-1-2">
                                <img :src="`${images}/place.svg`" alt="Place icon">
                                <span class="uk-margin-left">{{ detail_applicant.city }}</span>
                            </div>
                        </div>
                        <hr class="uk-text-nowrap">
                        <div class="uk-flex uk-flex-stretch">
                            <div class="uk-flex uk-flex-middle uk-margin-top uk-width-1-1">
                                <img :src="`${images}/person.svg`" alt="Person icon">
                                <span class="uk-margin-left uk-text-bold uk-text-muted">Candidate Summary</span>
                            </div>
                        </div>
                        <div class="uk-flex list-candidate">
                            <div class="uk-flex uk-flex-column uk-margin-top uk-width-1-3 uk-margin-right">
                                <div>
                                    <span class="uk-text-meta uk-text-bold">Latest Education</span>
                                </div>
                                <div>
                                    <span v-if="detail_applicant.educations.length > 0" class="uk-text-small uk-text-bold">
                                        {{ detail_applicant.educations[detail_applicant.educations.length-1].institution_name }}<br>
                                        {{ detail_applicant.educations[detail_applicant.educations.length-1].majors }}
                                    </span>
                                    <span v-else class="uk-text-small uk-text-bold">
                                        -<br>-
                                    </span>
                                </div>
                            </div>
                            <div class="uk-flex uk-flex-column uk-margin-top uk-width-1-3 uk-margin-right">
                                <div>
                                    <span class="uk-text-meta uk-text-bold">Last Job</span>
                                </div>
                                <div>
                                    <span v-if="detail_applicant.work_experiences.length > 0" class="uk-text-small uk-text-bold">
                                        {{ detail_applicant.work_experiences[detail_applicant.work_experiences.length-1].company_name }}<br>
                                        {{ detail_applicant.work_experiences[detail_applicant.work_experiences.length-1].title }}
                                    </span>
                                    <span v-else class="uk-text-small uk-text-bold">
                                        -<br>-
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-flex list-candidate">
                            <div class="uk-flex uk-flex-column uk-margin-top uk-width-1-3 uk-margin-right">
                                <div>
                                    <span class="uk-text-meta uk-text-bold">User Since</span>
                                </div>
                                <div>
                                    <span class="uk-text-small uk-text-bold">{{ datetime.fromISO(detail_applicant.user_since).toFormat('dd LLLL yyyy') || '-' }}</span>
                                </div>
                            </div>
                            <div class="uk-flex uk-flex-column uk-margin-top uk-width-1-3 uk-margin-right">
                                <div>
                                    <span class="uk-text-meta uk-text-bold">Years of Professional Experience</span>
                                </div>
                                <div>
                                    <span class="uk-text-small uk-text-bold">
                                        {{ calcYearJobExp(detail_applicant.work_experiences[detail_applicant.work_experiences.length-1].end_date) || '-'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr class="uk-text-nowrap">
                        <div class="uk-flex uk-flex-stretch">
                            <div class="uk-flex uk-flex-middle uk-margin-top uk-width-1-1">
                                <img :src="`${images}/verified.svg`">
                                <span class="uk-margin-left uk-text-bold uk-text-muted">Qualification</span>
                            </div>
                        </div>
                        <div class="uk-flex list-candidate">
                            <div class="uk-flex uk-flex-column uk-margin-top uk-margin-right uk-width-1-3">
                                <div>
                                    <span class="uk-text-muted uk-text-bold">Form Identification</span>
                                </div>
                                <div class="uk-flex uk-margin-small-top">
                                    <div class="uk-width-1-2 uk-text-nowrap">
                                        <span>SIM</span>
                                    </div>
                                    <div v-if="detail_applicant.sim" class="uk-width-1-2 uk-text-right">
                                        <img :src="`${images}/check-circle.svg`" class="img-icon">
                                        <span class="uk-margin-small-left">Yes</span>
                                    </div>
                                    <div v-else class="uk-width-1-2 uk-text-right">
                                        <img :src="`${images}/minus_circle.svg`" class="img-icon">
                                    </div>
                                </div>
                                <hr class="uk-margin-remove">
                                <div class="uk-flex uk-margin-small-top">
                                    <div class="uk-width-1-2 uk-text-nowrap">
                                        <span>KTP</span>
                                    </div>
                                    <div v-if="detail_applicant.ktp" class="uk-width-1-2 uk-text-right">
                                        <img :src="`${images}/check-circle.svg`" class="img-icon">
                                        <span class="uk-margin-small-left">Yes</span>
                                    </div>
                                    <div v-else class="uk-width-1-2 uk-text-right">
                                        <img :src="`${images}/minus_circle.svg`" class="img-icon">
                                    </div>
                                </div>
                                <hr class="uk-margin-remove">
                                <div class="uk-flex uk-margin-small-top">
                                    <div class="uk-width-1-2 uk-text-nowrap">
                                        <span>Ijazah</span>
                                    </div>
                                    <div v-if="detail_applicant.ijazah" class="uk-width-1-2 uk-text-right">
                                        <img :src="`${images}/check-circle.svg`" class="img-icon">
                                        <span class="uk-margin-small-left">Yes</span>
                                    </div>
                                    <div v-else class="uk-width-1-2 uk-text-right">
                                        <img :src="`${images}/minus_circle.svg`" class="img-icon">
                                    </div>
                                </div>
                                <hr class="uk-margin-remove">
                                <div class="uk-flex uk-margin-small-top">
                                    <div class="uk-width-1-2 uk-text-nowrap">
                                        <span>SKCK</span>
                                    </div>
                                    <div v-if="detail_applicant.skck" class="uk-width-1-2 uk-text-right">
                                        <img :src="`${images}/check-circle.svg`" class="img-icon">
                                        <span class="uk-margin-small-left">Yes</span>
                                    </div>
                                    <div v-else class="uk-width-1-2 uk-text-right">
                                        <img :src="`${images}/minus_circle.svg`" class="img-icon">
                                    </div>
                                </div>
                                <hr class="uk-margin-remove">
                            </div>
                        </div>
                        <hr class="uk-margin-top">
                        <div class="uk-flex uk-flex-stretch">
                            <div class="uk-flex uk-flex-middle uk-margin-top uk-width-1-1">
                                <img :src="`${images}/education.svg`">
                                <span class="uk-margin-left uk-text-bold uk-text-muted">Education</span>
                            </div>
                        </div>
                        <div v-if="detail_applicant.educations.length > 0">
                            <div v-for="(education, index) in detail_applicant.educations" :key="index" class="uk-flex list-candidate uk-margin-top">
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-default uk-text-bold">{{ education.institution_name }}</span><br>
                                        <span class="uk-text-default">{{ education.majors }}</span>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-default">{{ dateFormat(education.start_date) }} - {{ dateFormat(education.end_date) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="uk-flex list-candidate">
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-default">-</span><br>
                                        <span class="uk-text-default">-</span>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-default">-</span><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="uk-margin-top">
                        <div class="uk-flex uk-flex-stretch">
                            <div class="uk-flex uk-flex-middle uk-margin-top uk-width-1-1">
                                <img :src="`${images}/experience.svg`">
                                <span class="uk-margin-left uk-text-bold uk-text-muted">Experience</span>
                            </div>
                        </div>
                        <div v-if="detail_applicant.work_experiences.length > 0">
                            <div v-for="(experience, index) in detail_applicant.work_experiences" :key="index" class="uk-flex list-candidate uk-margin-top">
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-default uk-text-bold">{{ experience.company_name }}</span><br>
                                        <span class="uk-text-default">{{ experience.title }}</span>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-default">{{ dateFormat(experience.start_date) }} - {{ dateFormat(experience.end_date) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="uk-flex list-candidate uk-margin-top">
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-default uk-text-bold">-</span><br>
                                        <span class="uk-text-default">-</span>
                                    </div>
                                </div>
                                <div class="uk-flex uk-flex-column uk-width-1-3 uk-margin-right">
                                    <div>
                                        <span class="uk-text-default">-</span><br>
                                        <span class="uk-text-default">-</span><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="uk-margin-top">
                    </li>
                    <li>
                        <div class="uk-card uk-card-default uk-margin-top">
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                                    <thead>
                                        <tr>
                                            <th class="uk-text-nowrap">Job Title</th>
                                            <th class="uk-text-nowrap">Company</th>
                                            <th class="uk-text-nowrap">Location</th>
                                            <th class="uk-text-nowrap">Test Score</th>
                                            <th class="uk-text-nowrap">Status</th>
                                        </tr>
                                    </thead>
                                    <loading-table v-if="tableLoading" :colspan="10"/>
                                    <template v-else>
                                        <tbody v-if="all_application !== null && all_application.docs.length > 0">
                                            <tr v-for="(item, index) in all_application.docs" :key="index">
                                                <td class="uk-text-nowrap">{{ item.job_title || '-' }}</td>
                                                <td class="uk-text-nowrap">{{ item.company ? item.company.name : '-' }}</td>
                                                <td class="uk-text-nowrap">{{ item.city || '-' }}</td>
                                                <td class="uk-text-nowrap" v-if="item.all_user_scores.length < 1">-</td>
                                                <td class="uk-text-nowrap" v-else-if="item.all_user_scores.length > 0">
                                                    <p
                                                        v-for="(test, index) in item.all_user_scores"
                                                        :key="index"
                                                    >{{ test.test_category_id.name || '-' }} - {{ test.score || '-' }}</p>
                                                </td>
                                                <td class="uk-text-nowrap">Sent to HRD</td>
                                            </tr>
                                        </tbody>
                                        <empty-table v-else :colspan="5"/>
                                    </template>
                                </table>
                            </div>
                            <pagination
                                v-if="all_application !== null"
                                :total-data="all_application.totalDocs"
                                :change-limit="changeLimit"
                                :change-page="changePage"
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import Pagination from '@/components/globals/Pagination';
import { mapActions } from 'vuex';
import formatter from "@/utils/formatter";
import { DateTime } from 'luxon';
import moment from 'moment';
moment.locale('id');
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import { getUserLogin } from '@/utils/auth';

export default {
    name: 'DetailJobApplicantSilverPackage',
    components: {
        Pagination,
        EmptyTable,
        LoadingTable
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            meta: {
                limit: 50,
                page: 1,
                user_id: '',
                type: 'silver'
            },
            detail_applicant: null,
            all_application: null,
            isLoading: false,
            datetime: DateTime,
            tableLoading: false
        };
    },
    async mounted() {
        try {
            this.isLoading = true;
            this.detail_applicant = await this.detailApplicantSilverPackage(this.$route.params.job_applicant_id);
            this.isLoading = false;

            this.tableLoading = true;
            this.meta.user_id = this.detail_applicant.user_id;
            this.all_application = await this.listAllJobApplication(this.meta);
            this.tableLoading = false;
        } catch (error) {
            this.isLoading = false;
            this.tableLoading = false;
        }
    },
    computed: {
        user_cred() {
            return getUserLogin();
        }
    },
    methods: {
        ...mapActions({
            detailApplicantSilverPackage: 'job/detailApplicantSilverPackage',
            listAllJobApplication: 'job/listAllJobApplication'
        }),
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        dateFormat(data) {
            return formatter.dateComplete(data);
        },
        calcYearJobExp(job_exp) {
            const now = moment();
            const years = now.diff(job_exp, 'year');
            const months = now.diff(job_exp, 'months');
            return years + ' Year, ' + months + ' Month';
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    }
};
</script>

<style scoped>
.list-candidate {
    margin-left: 45px;
}
</style>
